<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <h1>Aprovação de Férias</h1>
    <Divider />
    <div class="p-field p-grid">
      <div class="p-col-12 p-md-3 p-mt-1">
        <label for="year-selector">Ano</label>
        <InputNumber
          id="year-selector"
          name="year-selector"
          :value="selectedYear"
          class="p-col-10"
          inputStyle="width: 75px"
          showButtons
          buttonLayout="horizontal"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          :step="1"
          :min="currentYear - 10"
          :max="currentYear"
          :useGrouping="false"
          @input="yearChanged"
        />
      </div>
      <div class="p-col-12 p-md-3 p-mt-1">
        <Button
          label="Férias Aprovadas"
          class="p-button-outlined"
          v-if="showingNotApproved"
          @click="toogleVacationStatus"
        />

        <Button
          label="Férias Por Aprovadas"
          class="p-button-outlined"
          v-if="!showingNotApproved"
          @click="toogleVacationStatus"
        />
      </div>
    </div>
    <Divider />
    <div style="height: 65vh">
      <DataTable
        :value="vacationsList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        responsiveLayout="scroll"
        :scrollable="true"
        scrollHeight="flex"
      >
        <template #empty> Não existem funcionários. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <Column
          field="user_name"
          header="Funcionário"
          sortable
          filterField="user_name"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
          <template #body="slotProps">
            {{ slotProps.data.user_name }}
          </template>
        </Column>
        <Column
          field="dateStart"
          header="Data de Início"
          filterField="dateStart"
          dataType="date"
          sortable
        >
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
          <template #body="slotProps">
            {{ slotProps.data.start }}
          </template>
        </Column>
        <Column
          field="dateEnd"
          header="Data de Fim"
          filterField="dateEnd"
          dataType="date"
          sortable
        >
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
          <template #body="slotProps">
            {{ slotProps.data.end }}
          </template>
        </Column>
        <Column
          field="number_days"
          header="Dias"
          filterField="number_days"
          dataType="numeric"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
          <template #body="slotProps">
            {{ slotProps.data.number_days }}
          </template>
        </Column>
        <Column
          field="approved"
          header="Aprovado"
          filterField="approved"
          dataType="boolean"
        >
          <template #body="slotProps">
            {{ slotProps.data.approved == true ? "Sim" : "Não" }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: false, label: 'Não' },
                { value: true, label: 'Sim' },
              ]"
              optionLabel="label"
              optionValue="value"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          field="observations"
          header="Observações"
          filterField="observations"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
          <template #body="slotProps">
            {{ slotProps.data.observations }}
          </template>
        </Column>
        <Column
          field="createDate"
          header="Data de Criação"
          filterField="createDate"
          dataType="date"
          sortable
        >
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
          <template #body="slotProps">
            {{ slotProps.data.created_at }}
          </template>
        </Column>
        <Column v-if="showingNotApproved">
          <template #body="slotProps">
            <Button
              icon="pi pi-check"
              class="p-button-rounded p-button-success p-button-outlined"
              @click="confirmAproveVacation(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      :visible.sync="aproveVacationDialog"
      :position="$isMobile() ? '' : 'top'"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      header="Aprovar Férias"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="vacation">
          <span>
            Tem a certeza que pretende aprovar as férias do funcionário
            <u>{{ vacation.user_name }}</u> com
            <u>início no dia {{ vacation.start }}</u> e
            <u>fim no dia {{ vacation.end }}</u>
            (<u>{{ vacation.number_days }} dias de férias</u>)?
          </span>
        </span>
      </div>
      <template #footer>
        <Button
          label="Aprovar"
          icon="pi pi-check"
          class="p-button-text p-button-success"
          @click="approveVacation"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text p-button-danger"
          @click="aproveVacationDialog = false"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import vacationsService from "../services/vacations.service";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "vacationApprove",
  data() {
    return {
      filters: {
        user_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        dateStart: { value: null, matchMode: FilterMatchMode.DATE_IS },
        dateEnd: { value: null, matchMode: FilterMatchMode.DATE_IS },
        createDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        number_days: { value: null, matchMode: FilterMatchMode.EQUALS },
        approved: { value: null, matchMode: FilterMatchMode.EQUALS },
        observations: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      loading: true,
      vacationsList: [],
      selectedYear: new Date().getFullYear() - 1,
      currentYear: new Date().getFullYear(),
      vacation: {},
      aproveVacationDialog: false,
      showingNotApproved: true,
    };
  },
  created() {
    this.getVacationsNotApproved();
  },
  methods: {
    yearChanged(year) {
      this.selectedYear = year;
      this.getVacationsNotApproved();
    },
    getVacationsNotApproved() {
      this.loading = true;
      return vacationsService
        .getVacationsNotApproveList(this.selectedYear)
        .then((response) => {
          response = response.map((v) => ({
            ...v,
            dateStart: new Date(v.start),
            dateEnd: new Date(v.end),
            createDate: new Date(v.created_at),
          }));
          this.vacationsList = response;
          this.showingNotApproved = true;
          this.loading = false;
        });
    },
    getVacationsApproved() {
      this.loading = true;
      return vacationsService
        .getVacationsApproveList(this.selectedYear)
        .then((response) => {
          this.vacationsList = response;
          this.showingNotApproved = false;
          this.loading = false;
        });
    },
    confirmAproveVacation(vacations) {
      this.vacation = vacations;
      this.aproveVacationDialog = true;
    },
    approveVacation() {
      this.loading = true;
      let start = this.vacation.start;
      let end = this.vacation.end;
      let userId = this.vacation.user_id;

      this.vacation = {};
      this.aproveVacationDialog = false;

      const areYou = (vacationAux) => {
        return (
          vacationAux.user_id == userId &&
          vacationAux.start == start &&
          vacationAux.end == end
        );
      };

      return vacationsService
        .approveVacation(userId, start, end, 1)
        .then((response) => {
          this.loading = false;
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao aprovar as férias",
              life: 3000,
            });
          }
          let vacationIndex = this.vacationsList.findIndex(areYou);
          if (vacationIndex >= 0) {
            this.vacationsList.splice(vacationIndex, 1);
          }
          return this.$toast.add({
            severity: "success",
            summary: "Férias aprovadas",
            detail: "As férias foram aprovadas com sucesso",
            life: 3000,
          });
        });
    },
    toogleVacationStatus() {
      if (this.showingNotApproved) {
        return this.getVacationsApproved();
      }
      return this.getVacationsNotApproved();
    },
  },
};
</script>
<style scoped lang="scss">
.p-column-filter {
  width: 100%;
}
</style>
